import styled from "styled-components";
import media from "styled-media-query";
import { Map } from "styled-icons/boxicons-solid/Map";
import { Work } from "styled-icons/material/Work";
import { AttachMoney } from "styled-icons/material/AttachMoney";
import { ChartLine } from "styled-icons/fa-solid/ChartLine";
// import { LinkExternal } from "styled-icons/boxicons-regular/LinkExternal";
import { Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export const MapMarker = styled(Map)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const SuitCaseIcon = styled(Work)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const AttachMoneyIcon = styled(AttachMoney)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
export const GraduationIcon = styled(ChartLine)`
  color: var(--esx-red);
  height: 1.2rem;
  width: 1.2rem;
`;
// export const LinkExternalIcon = styled(LinkExternal)`
//   color: #fafafa;
//   height: 1.5rem;
//   width: 1.5rem;
//   margin-left: 1.5rem;
// `;

export const JobWrapper = styled.section`
  padding: 2rem var(--default-padding-desktop);
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 2rem;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
    grid-template-columns: 2fr 1fr;
  `}
  ${media.lessThan("small")`
    padding: var(--default-padding-mobile);
    grid-template-columns: 1fr;
  `}
`;
export const JobSidebar = styled.aside`
  background-color: var(--esx-red);
  border-radius: 1rem;
  height: fit-content;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .content {
    h2 {
      color: #fafafa;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    padding: 2rem;
  }
  ${media.lessThan("small")`
    flex-direction: column-reverse; 
  `}
`;
export const JobPostWrapper = styled.section``;
export const JobPostText = styled.section``;
export const JobPostItems = styled.section`
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  text-align: left;
  width: 100%;

  ${media.lessThan("medium")`
    grid-gap: .5rem;
    padding-top: 1rem;
  `}
`;
export const JobPostSkills = styled.ul`
  list-style-type: none;
  font-size: 0.8rem;
  width: 100%;
  padding-top: 1rem;
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
`;
export const JobPostItem = styled.p`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  font-size: 0.8rem;
`;
export const Title = styled.h1`
  font-size: var(--medium-title-size);
  color: var(--esx-red);
  font-weight: 700;
`;
export const Subtitle = styled.h2`
  font-size: var(--small-title-size);
  font-weight: bold;
`;
export const Text = styled.div`
  margin: 1rem 0;
  width: 100%;
  pre {
    font-family: "Rubik";
    word-wrap: break-word;
    white-space: pre-wrap;
    padding: 0;
    background: transparent;
  }
`;
export const Skill = styled.li`
  border: 1px solid var(--esx-red);
  text-align: center;
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Button = styled.a`
  color: #fafafa;
  border: 2px solid #fafafa;
  padding: 0.8rem;
  border-radius: 50px;
  &:hover {
    background-color: #fafafa;
    color: var(--esx-red);
  }
`;

export const JobItemImage = styled(BackgroundImage)`
  width: 100%;
  height: 25rem;
  &::after {
    border-radius: 1rem 1rem 0 0;
  }
  &::before {
    border-radius: 1rem 1rem 0 0;
  }
  ${media.lessThan("small")`
    &::after {
      border-radius: 0 0 1rem 1rem ;
    }
    &::before {
      border-radius: 0 0 1rem 1rem ;
    }
  `}
`;

export const ButtonLink = styled.a`
  color: #fafafa;
  border: 2px solid #fafafa;
  padding: 0.8rem;
  border-radius: 50px;
  transition: all 0.4s;
  &:hover {
    background-color: #fafafa;
    color: var(--esx-red);
  }
`;
