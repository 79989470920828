import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import CtaBanner from "../components/CtaBanner";

import * as S from "../components/Job/styled";

const Job = ({ data, pageContext }) => {
  const job = data.jobData;

  return (
    <Layout>
      <SEO
        title={job.frontmatter.title}
        description={job.frontmatter.description}
        image={data.seoImg}
      />
      <S.JobWrapper>
        <S.JobPostWrapper>
          <S.JobPostText>
            <S.Title>{job.frontmatter.title}</S.Title>
            <S.JobPostItems>
              <S.JobPostItem>
                <S.MapMarker />
                {job.frontmatter.locale}
              </S.JobPostItem>
              <S.JobPostItem>
                <S.GraduationIcon />
                {job.frontmatter.level}
              </S.JobPostItem>
              <S.JobPostItem>
                <S.SuitCaseIcon />
                {job.frontmatter.contract}
              </S.JobPostItem>
              <S.JobPostItem>
                <S.AttachMoneyIcon />
                {job.frontmatter.salary > 0
                  ? `R$ ${job.frontmatter.salary.toLocaleString()}`
                  : "A combinar"}
              </S.JobPostItem>
            </S.JobPostItems>
            {job.frontmatter.description ? (
              <>
                <S.Subtitle>Descrição da vaga</S.Subtitle>
                <S.Text>
                  <pre>{job.frontmatter.description}</pre>
                </S.Text>
              </>
            ) : null}
            {job.frontmatter.required ? (
              <>
                <S.Subtitle>Requisitos da vaga</S.Subtitle>
                <S.Text>
                  <pre>{job.frontmatter.required}</pre>
                </S.Text>
              </>
            ) : null}
            {job.frontmatter.differential ? (
              <>
                <S.Subtitle>Diferencial</S.Subtitle>
                <S.Text>
                  <pre>{job.frontmatter.differential}</pre>
                </S.Text>
              </>
            ) : null}
            {job.frontmatter.benefits ? (
              <>
                <S.Subtitle>Benefícios</S.Subtitle>
                <S.Text>
                  <pre>{job.frontmatter.benefits}</pre>
                </S.Text>
              </>
            ) : null}
          </S.JobPostText>
          <S.Subtitle>Habilidades</S.Subtitle>
          <S.JobPostSkills>
            {job.frontmatter.skills.map((skill, i) => (
              <S.Skill key={i} title={skill}>
                {skill}
              </S.Skill>
            ))}
          </S.JobPostSkills>
        </S.JobPostWrapper>
        <S.JobSidebar>
          <S.JobItemImage fluid={data.jobImageFixed.childImageSharp.fluid} />
          <div className="content">
            <S.Subtitle>Se identificou com a vaga?</S.Subtitle>
            <S.ButtonLink
              href="/vaga-candidatar"
              state={{
                modal: false,
                jobSlug: "/",
                jobTitle: job.frontmatter.title,
              }}
            >
              Candidatar-se
            </S.ButtonLink>
          </div>
        </S.JobSidebar>
      </S.JobWrapper>
      <CtaBanner
        title="Não encontrou uma vaga?"
        subtitle="Entre para nosso banco de talentos"
        buttonText="Banco de talentos"
        buttonLink="/banco-de-talentos"
        openAsExternalLink
      />
    </Layout>
  );
};

export const query = graphql`
  query Job($slug: String!) {
    jobData: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        description
        required
        differential
        benefits
        title
        contract
        level
        locale
        salary
        skills
      }
    }
    jobImageFixed: file(relativePath: { eq: "cover_cultura_960x720px.jpg" }) {
      childImageSharp {
        fluid(
          maxWidth: 970
          maxHeight: 720
          quality: 100
          fit: FILL
          toFormat: WEBP
        ) {
          ...GatsbyImageSharpFluid_withWebp
          src
        }

        fixed(width: 970, height: 720) {
          src
        }
      }
    }
    seoImg: file(relativePath: { eq: "thumbnail_vagas_500x300px.png" }) {
      childImageSharp {
        fixed(width: 500, height: 300) {
          src
        }
      }
    }
  }
`;

export default Job;
